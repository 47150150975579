////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(236, 92, 40, 1);
}
.bg-thirdColor {
  background-color: rgba(87, 74, 68, 1);
}
.bg-fourthColor {
  background-color: rgb(0, 0, 0);
}
.bg-fifthColor {
  background-color: rgba(139, 100, 59, 1);
}
.bg-sixthColor {
  background-color: rgba(199, 155, 108, 1);
}
.bg-seventhColor {
  background-color: rgba(139, 100, 59, 1);
}
